import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import PathwaySettingsLabel from "@/admin/pathways/settings/PathwaySettingsLabel"
import { useLabel } from "@/core/context/LabelsContext"
import { ProductRegistrationAvailability } from "@/core/context/__generated__/ActiveProductContextFragment.graphql"
import ProductUtils from "@/product/util/productUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoFormControl } from "@disco-ui"
import DiscoRadioBox from "@disco-ui/radio/DiscoRadioBox"
import { RadioGroup } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import React from "react"

type Props = TestIDProps & {
  form: PathwaySettingsFormStore
}

function PathwayAccessRadioGroup(props: Props) {
  const { testid = "PathwayAccessRadioGroup", form } = props
  const memberLabel = useLabel("admin_member")
  const classes = useStyles()

  return (
    <>
      <DiscoFormControl
        marginBottom={1.5}
        label={<PathwaySettingsLabel title={"Access"} />}
        sublabel={"Choose who can see and register for this Pathway."}
        errorMessages={form.errorsByField.registrationAvailability}
      >
        <RadioGroup
          onChange={handleAvailabilityChange}
          value={form.state.registrationAvailability}
          row
          classes={{ root: classes.radioGroup }}
        >
          <DiscoRadioBox
            testid={`${testid}.radio.public`}
            value={"public"}
            icon={ProductUtils.getAvailabilityIcon("public")}
            title={ProductUtils.getAvailabilityLabel("public", memberLabel)}
            subtitle={"Anyone on the internet can see and register for this Pathway."}
          />
          <DiscoRadioBox
            testid={`${testid}.radio.private`}
            value={"private"}
            icon={ProductUtils.getAvailabilityIcon("private")}
            title={ProductUtils.getAvailabilityLabel("private", memberLabel)}
            subtitle={`Only ${memberLabel.plural} can see and register for this Pathway.`}
          />
          <DiscoRadioBox
            testid={`${testid}.radio.hidden`}
            value={"hidden"}
            icon={ProductUtils.getAvailabilityIcon("hidden")}
            title={"Hidden"}
            subtitle={
              "Hide this Pathway from the Explore page so no new registrations are allowed."
            }
          />
        </RadioGroup>
      </DiscoFormControl>
    </>
  )

  function handleAvailabilityChange(event: React.ChangeEvent<HTMLInputElement>) {
    form.state.registrationAvailability = event.target
      .value as ProductRegistrationAvailability
  }
}

const useStyles = makeUseStyles((theme) => ({
  radioGroup: {
    gap: theme.spacing(1.5),
  },
}))

export default observer(PathwayAccessRadioGroup)
