import CreatePathwayGroupsForm from "@/admin/pathways/CreatePathwayGroupsForm"
import { CreatePathwayFormInitialState } from "@/admin/pathways/hooks/useCreatePathwayForm"
import PathwaySettingsAvailability from "@/admin/pathways/settings/PathwaySettingsAvailability"
import PathwaySettingsDetails from "@/admin/pathways/settings/PathwaySettingsDetails"
import { PathwaySettingsFormFragment$key } from "@/admin/pathways/settings/__generated__/PathwaySettingsFormFragment.graphql"
import {
  PathwaySettingsFormMutation,
  UpdatePathwayInput,
} from "@/admin/pathways/settings/__generated__/PathwaySettingsFormMutation.graphql"
import { useDrawerContext } from "@/core/context/DrawerContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { PickNonNullable, TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

type PathwaySettingsFormState = PickNonNullable<UpdatePathwayInput, "pathwayGroups"> &
  Pick<CreatePathwayFormInitialState, "productCache">
export type PathwaySettingsFormStore = FormStore<
  PathwaySettingsFormState,
  PathwaySettingsFormMutation
>

type Props = TestIDProps & {
  productKey: PathwaySettingsFormFragment$key
}

function PathwaySettingsForm(props: Props) {
  const { testid = "PathwaySettingsForm", productKey } = props
  const classes = useStyles()
  const drawer = useGlobalDrawer("pathwaySettings")
  const { closeDrawer } = useDrawerContext()
  const { setUnsavedChanges, handleLeave } = useUnsavedChangesModalContext()
  const [isPublishWarningOpen, setIsPublishWarningOpen] = useState(false)
  const productLabel = useLabel("admin_experience")

  const product = useFragment<PathwaySettingsFormFragment$key>(
    graphql`
      fragment PathwaySettingsFormFragment on Product {
        id
        name
        slug
        description
        cover
        status
        registrationAvailability
        richEditorCheckoutDescription
        badge {
          kind
          icon
          color
          emoji
          mediaUrl
        }
        landingPage {
          mode
          metaTitle
          metaDescription
          metaImageUrl
        }
        childPathwayGroups {
          edges {
            node {
              id
              title
              products {
                edges {
                  node {
                    id
                    name
                    badge {
                      kind
                      icon
                      color
                      emoji
                      mediaUrl
                    }
                    ...PathwaySequenceProductFragment
                  }
                }
              }
            }
          }
        }
        ...ExperienceSettingsStatusRadioGroupFragment
      }
    `,
    productKey
  )

  // Build the pathway groups input and product cache from the current pathway groups
  const pathwayGroups = []
  const productCache: PathwaySettingsFormState["productCache"] = {}
  for (const g of Relay.connectionToArray(product.childPathwayGroups)) {
    const products = Relay.connectionToArray(g.products)
    pathwayGroups.push({
      id: g.id,
      title: g.title,
      productIds: products.map((p) => p.id),
    })
    for (const p of products) {
      productCache[p.id] = { name: p.name, badge: p.badge! }
    }
  }

  const form = useFormStore<PathwaySettingsFormMutation, PathwaySettingsFormState>(
    graphql`
      mutation PathwaySettingsFormMutation($input: UpdatePathwayInput!) {
        response: updatePathway(input: $input) {
          node {
            ...PathwaySettingsFormFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      productId: product.id,
      name: product.name,
      slug: product.slug,
      description: product.description,
      cover: product.cover,
      status: product.status,
      registrationAvailability: product.registrationAvailability,
      richEditorCheckoutDescription: product.richEditorCheckoutDescription,
      badge: {
        ...product.badge,
        kind: product.badge?.kind || "icon",
      },
      landingPage: { ...product.landingPage },
      pathwayGroups,
      // Extra state that won't be submitted with the mutation
      productCache,
    }
  )

  useEffect(() => {
    setUnsavedChanges(form.isChanged)
  }, [form.isChanged, setUnsavedChanges])

  if (!product) return null

  return (
    <Form
      testid={testid}
      classes={{ formFieldsContainer: classes.form }}
      onSubmit={handleSubmit}
      height={"100%"}
      hasStickyButtons
      buttons={
        <>
          <DiscoButton
            testid={`${testid}.cancel`}
            color={"grey"}
            variant={"outlined"}
            onClick={() => handleLeave({ onLeave: () => closeDrawer?.() })}
          >
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton testid={`${testid}.submit`} form={form}>
            {"Save"}
          </Form.SubmitButton>
        </>
      }
    >
      {renderSettingsFormFields()}

      <DiscoWarningModal
        testid={`${testid}.publish-warning`}
        variant={"primary"}
        icon={"rocket"}
        title={"Publishing Pathway"}
        modalContentLabel={"Publishing Pathway"}
        isOpen={isPublishWarningOpen}
        description={`Are you sure you want to publish this Pathway? You will not be able to change it back to draft, or add any draft ${productLabel.plural} to it.`}
        onClose={() => setIsPublishWarningOpen(false)}
        confirmationButtonProps={{
          onClick: () => {
            handleSubmit()
            setIsPublishWarningOpen(false)
          },
          children: "Yes, publish it",
          disabled: form.disabled,
          shouldDisplaySpinner: form.isSubmitting,
        }}
      />
    </Form>
  )

  function renderSettingsFormFields() {
    switch (drawer.params.pathwaySettingsTab) {
      case "availability":
        return <PathwaySettingsAvailability form={form} />
      case "sequence":
        return <CreatePathwayGroupsForm form={form} />
      case "details":
      default:
        return <PathwaySettingsDetails form={form} productKey={product} />
    }
  }

  async function handleSubmit() {
    const { productCache: _, ...changedState } = form.changedState

    // If publishing the pathway, show a warning before submitting
    if (changedState.status === "published" && !isPublishWarningOpen) {
      setIsPublishWarningOpen(true)
      return
    }

    const { didSave } = await form.submit({
      productId: form.state.productId,
      ...changedState,
      ...("badge" in changedState && {
        badge: form.state.badge,
      }),
    })
    if (!didSave) return
    displaySuccessToast({
      testid: `${testid}.success`,
      message: "Pathway updated!",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  form: {
    padding: theme.spacing(3, 3, 0),
  },
}))

export default observer(PathwaySettingsForm)
