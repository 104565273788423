import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
import { generatePath } from "react-router-dom"

type Props = {
  pathwayProductKey: any
} & TestIDProps
function usePathwayAdminDropdownItems({
  testid = "usePathwayAdminDropdownItems",
  pathwayProductKey,
}: Props) {
  const settingsDrawer = useGlobalDrawer("pathwaySettings")
  const certificateDrawer = useGlobalDrawer("certificates")

  const pathwayProduct = useFragment(
    graphql`
      fragment usePathwayAdminDropdownItemsFragment on Product {
        id
        slug
        ...usePermissionsFragment
      }
    `,
    pathwayProductKey
  )

  const permissions = usePermissions(pathwayProduct)
  const items = []
  if (permissions.has("registration.manage")) {
    items.push(
      <DiscoDropdownItem
        key={"registration"}
        testid={`${testid}.registration`}
        // TODO: navigate to pathway drawer once implemented
        to={generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
          productSlug: pathwayProduct.slug,
        })}
        title={"Registration Page"}
        icon={<DiscoIcon icon={"ticket"} />}
      />
    )
  }
  if (permissions.has("pathways.manage")) {
    items.push(
      <DiscoDropdownItem
        key={"settings"}
        testid={`${testid}.settings`}
        onClick={() => settingsDrawer.open({ pathwayProductId: pathwayProduct.id })}
        title={"Settings"}
        icon={<DiscoIcon icon={"settings"} />}
      />
    )
  }
  if (permissions.has("certificate_templates.manage")) {
    items.push(
      <DiscoDropdownItem
        key={"certificates"}
        testid={`${testid}.certificates`}
        onClick={() =>
          certificateDrawer.open({ drawerCertificatesProductId: pathwayProduct.id })
        }
        title={"Certificates"}
        icon={<DiscoIcon icon={"achievement"} />}
      />
    )
  }

  return items
}

export default usePathwayAdminDropdownItems
